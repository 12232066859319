<script setup>
  import { onMounted, ref } from 'vue';

  defineProps({
    modelValue: {
      type: [String, Number],
      default: ''
    }
  });

  defineEmits(['update:modelValue']);

  const input = ref(null);

  onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
      input.value.focus();
    }
  });

  defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <input
    ref="input"
    class="w-full rounded-md border-primary bg-gray-200 text-gray-800 shadow-sm focus:border-primary-darker focus:ring-purple-500 dark:bg-gray-800 dark:text-gray-100"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
